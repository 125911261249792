import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CallToAction from "../components/call-to-action"
import OurServices from "../components/our-services"

const IndexPage = () => (
  <Layout>
    <Seo title="Accueil" />
    <div className="section">
      <CallToAction />
    </div>
    <div className="section">
      <OurServices />
    </div>
  </Layout>
)

export default IndexPage
