import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

export default function OurServices(props) {
  return (
    <div class="row">
      <div class="col-12">
        <div class="heading">
          <h2>Nos services</h2>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-40 text-center text-md-left">
          <i class="d-inlin-block h2 mb-10 tf-ion-ios-alarm-outline"></i>
          <h4 class="font-weight-bold mb-2">
            Préparation d'échéancier de projet
          </h4>
          <p>
            Nous allons vous aider à bien planifier vos projets en établissant
            des échéancier clairs, réalistes et réalisables
          </p>
        </div>
        <div class="mb-40 text-center text-md-left">
          <i class="d-inlin-block h2 mb-10 tf-ion-ios-bell-outline"></i>
          <h4 class="font-weight-bold mb-2">
            Relevé de quantités 2d et sur maquette 5d
          </h4>
          <p>
            Nous allons vous aider à faire vos relevés de quantité (take-off)
            dans toutes les disciplines du bâtiment en 2D et à partir de
            maquettes.
          </p>
        </div>
        <div class="mb-40 text-center text-md-left">
          <i class="d-inlin-block h2 mb-10 tf-ion-ios-bell-outline"></i>
          <h4 class="font-weight-bold mb-2">
            Accompagement dans la gestion de vos projets
          </h4>
          <p>
            Vous êtes un donneur d'ouvrage et vous avez un projet, nous sommes
            là pour vous accompagner à toutes les étapes.
          </p>
        </div>
      </div>
      <div class="col-md-4 text-center align-self-center mb-4 mb-md-0 d-none d-md-block d-lg-block">
        <StaticImage class="img-fluid" src="../images/gantt-wood.png" alt="" />
      </div>
      <div class="col-md-4">
        <div class="mb-40 text-center text-md-left">
          <i class="d-inlin-block h2 mb-10 tf-ion-ios-bell-outline"></i>
          <h4 class="font-weight-bold mb-2">
            Analyse de constructibilité BIM 4D
          </h4>
          <p>
            Nous allions la puissance de la maquette 3D et l'échéancier pour
            deceler les embûches cachées dans l'exécution de vos projet.
          </p>
        </div>
        <div class="mb-40 text-center text-md-left">
          <i class="d-inlin-block h2 mb-10 tf-ion-ios-bell-outline"></i>
          <h4 class="font-weight-bold mb-2">
            Estimation budgétaire de coûts de projet
          </h4>
          <p>
            Nous allons vous aider à évaluer les valeurs de vos projets à partir
            du moment ou vous avez l'idée j'usqu'aux appels d'offre.
          </p>
        </div>
        <div class="mb-40 text-center text-md-left">
          <i class="d-inlin-block h2 mb-10 tf-ion-ios-bell-outline"></i>
          <h4 class="font-weight-bold mb-2">
            Conseils/ implémentation/ logiciel de gestion
          </h4>
          <p>
            Les outils de la gestion de la construction, nous en connaissons un
            bon bout. Laissez nous vous aider à l'analyse de vos besoins,
            l'intégration et la formation.
          </p>
        </div>
      </div>
    </div>
  )
}
