import * as React from "react"

export default function CallToAction(props) {
  return (
    <div className="row hero">
      <div className="col-md-12 align-self-center text-center text-md-left">
        <div className="block">
          <h1 className="font-weight-bold mb-4 font-size-60">
            Nous sommes des passionés de la construction et de ses technologies.
          </h1>
          <p className="mb-4">
            Notre objectif est d'utiliser notre expérience dans la construction
            et les atouts de la technologie afin de vous donner un avantage
            certain dans la réalisation de vos projets.
          </p>
          <a className="cta" href="mailto:info@themilestone.ca">
            Contactez nous
          </a>
        </div>
      </div>
    </div>
  )
}
